<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title> Top Companies By Time Spent (This Month) </v-card-title>
        <v-card-text>
          <trev-chart-company-top-by-ticket-time
            :companyCount="5"
            :dateMonthToUse="getDateThisMonth"
          >
          </trev-chart-company-top-by-ticket-time>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-title> Top Companies By Time Spent (Last Month) </v-card-title>
        <v-card-text>
          <trev-chart-company-top-by-ticket-time
            :companyCount="5"
            :dateMonthToUse="getDateLastMonth"
          >
          </trev-chart-company-top-by-ticket-time>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import trevChartCompanyTopByTicketTime from "../../../components/reports/sql/trev-chart-company-top-by-ticket-time.vue";
import * as utilities from "../../../utilities/GeneralUtilities";

export default {
  components: { trevChartCompanyTopByTicketTime },
  mounted() {},
  methods: {},
  computed: {
    getDateThisMonth(){
      return new Date();
    },
    getDateLastMonth(){
      var d = new Date();

      return new Date(d.getFullYear(), d.getMonth() - 1, 5);
    }
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>