<template>
  <div>
    <v-skeleton-loader type="card" v-if="!finishedLoading"> </v-skeleton-loader>
    <apexchart
      ref="chartusMaximus"
      type="bar"
      height="250"
      :options="chartOptionsData"
      :series="seriesData"
      v-else
    />
  </div>
</template>

<script>
import * as utilities from "../../../utilities/GeneralUtilities";

export default {
  props: {
    companyCount: {
      type: Number,
      default: 5,
    },
    dateMonthToUse: {
      type: Date,
      default: () => new Date(),
    },
  },
  computed: {
    seriesData() {
      if (this.finishedLoading) {
        return [
          {
            name: "Minutes",
            data: this.series[0].data.map((x) => x),
          },
        ];
      } else {
        return [];
      }
    },
    chartOptionsData() {
      if (this.finishedLoading) {
        return {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: this.chartOptions.xaxis.categories.map((x) => x),
          },
        };
      }
    },
  },
  mounted() {    
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      var minmaxMonth = utilities.getMinMaxDatesByDate(this.dateMonthToUse);      

      this.$courier.Zoho.GetTopCompaniesByTimeSpent({
        startDate: minmaxMonth.startDate,
        endDate: minmaxMonth.endDate,
        numberOfCompanies: this.companyCount,
      }).then((x) => {
        x.json().then((data) => {
          data.payload.forEach((reportItem) => {
            this.series[0].data.push(reportItem.timeSpent);
            this.chartOptions.xaxis.categories.push(reportItem.companyName);
          });

          this.finishedLoading = true;
        });
      });
    },
  },
  data() {
    return {
      finishedLoading: false,
      series: [
        {
          name: "Time Spent",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
};
</script>

<style>
</style>